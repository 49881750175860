import { useLocation, useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { AccountRoutes, RouteName } from "src/constants/routeName";
import logo from "src/assets/images/logo.png";
import logo2 from "src/assets/images/logo.png";
import { useMemo, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import gift from "src/assets/images/refer_and_earn.svg";
import logout from "src/assets/images/logout (1).svg";
import about from "src/assets/images/about (1).svg";
import legal from "src/assets/images/shield (1).svg";
import setting from "src/assets/images/Icon ionic-md-settings (1).svg";
import help from "src/assets/images/help-web-button (1).svg";
import { FaUserEdit } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { LogoutModal } from "src/layout/AccountLayout";

function Header() {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [openSignOut, setOpenSignOut] = useState<boolean>(false);
    const path = useLocation().pathname;
    const isAccountActive = useMemo<boolean>(() => AccountRoutes.includes(path), [path]);

    const handleCloseSignOut = () => setOpenSignOut(false);
    const handleOpenMenu = () => setShowMenu(true);
    const handleCloseMenu = () => setShowMenu(false);
    const handleHistory = () => {
        setShowMenu(false);
        navigate(RouteName.History);
    };
    const handleMyAccount = () => {
        setShowMenu(false);
        navigate(RouteName.Profile);
    };
    const handleHome = () => {
        setShowMenu(false);
        navigate(RouteName.Home);
    };
    const handleAbout = () => {
        setShowMenu(false);
        navigate(RouteName.About);
    };
    const handleFAQ = () => {
        setShowMenu(false);
        navigate(RouteName.FAQ);
    };
    const handleRecipient = () => {
        setShowMenu(false);
        navigate(RouteName.Recipient);
    };
    const handleReferral = () => {
        setShowMenu(false);
        navigate(RouteName.Referral);
    };
    const handleHelp = () => {
        setShowMenu(false);
        navigate(RouteName.Help);
    };
    const handleSetting = () => {
        setShowMenu(false);
        navigate(RouteName.Setting);
    };
    const handleLegal = () => {
        setShowMenu(false);
        navigate(RouteName.Legal);
    };
    const handleAccountAbout = () => {
        setShowMenu(false);
        navigate(RouteName.AccountAbout);
    };
    const handleSignOut = () => {
        setOpenSignOut(true);
        setShowMenu(false);
    };

    return (
        <div className="border-b-2 border-b-gray-200 static shadow-none bg-transparent w-full">
            <div className="px-2 max-w-[1280px] py-2 sm:px-[27px] w-full mx-auto">
                <div className="flex items-center h-11">
                    <div className="flex justify-center h-11 items-center">
                        <Link to={RouteName.Welcome}>
                            <img src={logo} alt="" height={35} className="h-[35px]" />
                        </Link>
                    </div>
                    <div className="hidden smx:flex items-center gap-x-8 text-black font-normal pl-4 text-base">
                        <NavLink
                            className={({ isActive }) =>
                                `${isActive ? "text-[#019267]" : "text-black"} text-xs sm:text-sm whitespace-nowrap`
                            }
                            to={RouteName.Home}>
                            Home
                        </NavLink>
                        <NavLink
                            className={({ isActive }) =>
                                `${isActive ? "text-[#019267]" : "text-black"} text-xs sm:text-sm whitespace-nowrap`
                            }
                            to={RouteName.About}>
                            About + Carrier
                        </NavLink>
                        <NavLink
                            className={({ isActive }) =>
                                `${isActive ? "text-[#019267]" : "text-black"} text-xs sm:text-sm whitespace-nowrap`
                            }
                            to={RouteName.FAQ}>
                            FAQ
                        </NavLink>
                    </div>
                    <div className="grow" />

                    <div>
                        <div className="block smx:hidden">
                            <button onClick={handleOpenMenu}>
                                <HiMenu className="w-6 h-6 text-[#019267]" />
                            </button>
                            <div
                                className={`${
                                    showMenu ? "right-0 left-0" : "left-[-10000px]"
                                } w-screen h-screen bg-[#019267] px-6 py-[22px] fixed z-50 top-0 bottom-0  overflow-y-auto duration-200 overflow-x-hidden`}>
                                <div className="flex w-full items-center justify-between">
                                    <img src={logo2} alt="" className="h-[35px] w-auto" />
                                    <button onClick={handleCloseMenu}>
                                        <IoCloseCircleOutline className="text-white w-6 h-6" />
                                    </button>
                                </div>
                                <div className="w-full mt-14 text-lg font-bold text-white flex flex-col items-start gap-y-7">
                                    <button onClick={handleHome}>Home</button>
                                    <button onClick={handleAbout}>About + Carrier</button>
                                    <button onClick={handleFAQ}>FAQ</button>
                                    <button onClick={handleRecipient}>Recipients</button>
                                    <button onClick={handleHistory}>History</button>
                                    <button>My Accounts</button>
                                    <div className="w-full pl-6 flex flex-col items-start gap-y-7">
                                        <button onClick={handleMyAccount} className="flex items-center gap-x-3">
                                            <FaUserEdit className="h-4 w-4" />
                                            Profile info
                                        </button>
                                        <button onClick={handleReferral} className="flex items-center gap-x-3">
                                            <img src={gift} alt="" className="h-4 w-4" />
                                            Refer and earn $20
                                        </button>
                                        <button onClick={handleHelp} className="flex items-center gap-x-3">
                                            <img src={help} alt="" className="h-4 w-4" />
                                            Help center
                                        </button>
                                        <button onClick={handleSetting} className="flex items-center gap-x-3">
                                            <img src={setting} alt="" className="h-4 w-4" />
                                            Setting
                                        </button>
                                        <button onClick={handleLegal} className="flex items-center gap-x-3">
                                            <img src={legal} alt="" className="h-4 w-4" />
                                            Legal
                                        </button>
                                        <button onClick={handleAccountAbout} className="flex items-center gap-x-3">
                                            <img src={about} alt="" className="h-4 w-4" />
                                            About
                                        </button>
                                        <button onClick={handleSignOut} className="flex items-center gap-x-3">
                                            <img src={logout} alt="" className="h-4 w-4" />
                                            Sign out
                                        </button>
                                        <LogoutModal handleClose={handleCloseSignOut} open={openSignOut} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden items-center gap-x-6 smx:flex ">
                            <NavLink
                                className={({ isActive }) =>
                                    `${isActive ? "text-[#019267]" : "text-black"} text-xs sm:text-sm whitespace-nowrap`
                                }
                                to={RouteName.Recipient}>
                                <div>Recipient</div>
                            </NavLink>
                            <NavLink
                                className={({ isActive }) =>
                                    `${isActive ? "text-[#019267]" : "text-black"} text-xs sm:text-sm whitespace-nowrap`
                                }
                                to={RouteName.History}>
                                <div>History</div>
                            </NavLink>
                            <Link
                                className={`${
                                    isAccountActive ? "text-[#019267]" : "text-black"
                                } text-xs sm:text-sm whitespace-nowrap flex items-center gap-x-2`}
                                to={RouteName.Profile}>
                                <div>My Account</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
