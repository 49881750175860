import { CSSProperties, ReactNode } from "react";
import ModalTitle from "./ModalTitle";
import { Modal } from "antd";

interface IProps {
    handleClose: () => void;
    open: boolean;
    title: string;
    children: ReactNode;
    titleClassName?: string;
    className?: string;
    bodyClassName?: string;
    mask?: boolean;
    style?: CSSProperties;
    bodyStyle?: CSSProperties;
    maskStyle?: CSSProperties;
    centered?: boolean;
}

export default function Model({
    mask,
    handleClose,
    titleClassName,
    open,
    className,
    title,
    bodyStyle,
    style,
    centered,
    maskStyle,
    bodyClassName,
    children,
}: IProps) {
    return (
        <Modal
            bodyStyle={bodyStyle}
            centered={centered}
            open={open}
            footer={null}
            closable={false}
            closeIcon={null}
            mask={mask ?? true}
            maskStyle={maskStyle}
            style={style}>
            <div className={`py-16 px-0 mx-0 w-full m-auto font-dm-sans ${bodyClassName ?? ""}`}>
                <div className="smd:px-12 w-full px-3">
                    <ModalTitle titleClassName={titleClassName} title={title} handleClose={handleClose} />
                </div>
                <div className={`smd:px-12 w-full px-3 ${className ?? ""}`}>{children}</div>
            </div>
        </Modal>
    );
}
