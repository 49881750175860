import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RouteName } from "src/constants/routeName";
import logo from "src/assets/images/logo.png";

export default function Header() {
    const [openMenu, setOpenMenu] = useState<boolean>();
    const navigate = useNavigate();
    const location = useLocation();

    const handleToggleMenu = () => setOpenMenu((prev) => !prev);

    const handleNavigation = (route: string) => {
        navigate(route);
        if (openMenu) setOpenMenu(false);
    };

    return (
        <header className="py-4 w-full">
            <div className="flex flex-row px-4 justify-between items-center h-11 py-1 w-full">
                <Link to={RouteName.Welcome}>
                    <img src={logo} alt="logo" height={32.3} width={80.36} className=" h-[32.3px] w-[80.36px]" />
                </Link>
                {/* start nav bar for desktop  view */}
                <nav className="w-full pl-10 items-center hidden md:flex">
                    <div className="flex items-center gap-x-8 text-black font-semibold text-base">
                        <button
                            className={`inline-block text-start nav-hover ${location.pathname === RouteName.Welcome && "text-primary"}`}
                            onClick={() => handleNavigation(RouteName.Welcome)}>
                            Home
                        </button>
                        <button
                            className={`inline-block text-start nav-hover ${location.pathname === RouteName.About && "text-primary"}`}
                            onClick={() => handleNavigation(RouteName.About)}>
                            About + Carrier
                        </button>
                        <button
                            className={`inline-block text-start nav-hover ${location.pathname === RouteName.FAQ && "text-primary"}`}
                            onClick={() => handleNavigation(RouteName.FAQ)}>
                            FAQ
                        </button>
                    </div>
                    <div className="grow" />
                    <div className="flex items-center gap-x-8">
                        {location.pathname !== RouteName.Login && (
                            <button
                                onClick={() => handleNavigation(RouteName.Login)}
                                className="px-[18px] py-[10px] text-black font-semibold text-base  nav-hover">
                                Log in
                            </button>
                        )}
                        {location.pathname !== RouteName.Register && (
                            <button
                                onClick={() => handleNavigation(RouteName.Register)}
                                className="px-[18px] py-[10px] rounded-lg text-white text-base font-semibold bg-primary nav-button-hover">
                                Sign up
                            </button>
                        )}
                    </div>
                </nav>
                {/* end nav bar for desktop  view */}

                {/* start menu button for mobile  view */}
                <button className="md:hidden block" onClick={handleToggleMenu}>
                    <HiMenu className="text-3xl main-menu" />
                </button>
                <nav
                    className={`${
                        openMenu === undefined ? "hidden" : openMenu ? "block nav-animate" : "nav-close-animate"
                    }  z-[10000] overflow-hidden absolute w-full left-0 right-0 top-[73px] bg-white md:hidden shadow-2xl`}>
                    <div className="px-4 pt-9 pb-6">
                        <div className="flex flex-col gap-y-8 text-black font-semibold text-base">
                            <button
                                className={`inline-block text-start nav-hover ${location.pathname === RouteName.Welcome && "text-primary"}`}
                                onClick={() => handleNavigation(RouteName.Welcome)}>
                                Home
                            </button>
                            <button
                                className={`inline-block text-start nav-hover ${location.pathname === RouteName.About && "text-primary"}`}
                                onClick={() => handleNavigation(RouteName.About)}>
                                About + Carrier
                            </button>
                            <button
                                onClick={() => handleNavigation(RouteName.FAQ)}
                                className={`mb-8 inline-block text-start nav-hover ${
                                    location.pathname === RouteName.FAQ && "text-primary"
                                }`}>
                                FAQ
                            </button>
                        </div>
                        <div className="flex items-center gap-x-2">
                            {location.pathname !== RouteName.Register && (
                                <button
                                    onClick={() => handleNavigation(RouteName.Register)}
                                    className="px-[18px] py-2 rounded-lg text-white text-base font-semibold bg-primary nav-button-hover">
                                    Sign up
                                </button>
                            )}
                            {location.pathname !== RouteName.Login && location.pathname !== RouteName.Register && (
                                <button
                                    onClick={() => handleNavigation(RouteName.Login)}
                                    className="px-[18px] py-2 text-black font-semibold text-base nav-hover">
                                    Log in
                                </button>
                            )}
                            {location.pathname === RouteName.Register && (
                                <button
                                    onClick={() => handleNavigation(RouteName.Login)}
                                    className="px-[18px] py-2 rounded-lg text-white text-base font-semibold bg-primary nav-button-hover">
                                    Login
                                </button>
                            )}
                        </div>
                    </div>
                </nav>
                {/* end menu button for mobile  view */}
            </div>
        </header>
    );
}
