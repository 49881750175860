import { IoCloseCircleOutline } from 'react-icons/io5';

interface IProps {
  handleClose: () => void;
  title: string;
  titleClassName?: string;
}

export default function ModalTitle({
  handleClose,
  title,
  titleClassName
}: IProps) {
  return (
    <div className="w-full flex justify-between">
      <div />
      <div className={`text-center text-2xl font-bold ${titleClassName ?? ''}`}>
        {title}
      </div>
      <div>
        <button
          className="rounded-full p-[2px] cursor-pointer"
          onClick={handleClose}
        >
          <IoCloseCircleOutline className="text-3xl" />
        </button>
      </div>
    </div>
  );
}
