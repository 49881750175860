import React from "react";
import { MdSignalWifiStatusbarNotConnected } from "react-icons/md";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Example "componentStack":
        //   in ComponentThatThrows (created by App)
        //   in ErrorBoundary (created by App)
        //   in div (created by App)
        //   in App
        // logErrorToMyService(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="flex h-screen w-full flex-1  p-5">
                    <div className="flex h-full w-full flex-col justify-center">
                        <div className="flex w-full justify-center">
                            <MdSignalWifiStatusbarNotConnected className="h-20 w-20 text-gray-400 md:h-40 md:w-40 " />
                        </div>
                        <div className="flex flex-col text-2xl text-gray-400 md:text-4xl ">
                            <span className="text-center">Opps! check your network</span>
                            <span className="text-center">And Please try later.</span>
                            <div className="mx-auto mt-6">
                                <a
                                    href={window.location.pathname}
                                    className="text-center text-base font-semibold bg-primary text-white px-8 py-2 rounded-xl">
                                    Reload
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
