import { combineReducers } from "@reduxjs/toolkit";

import { stateName as authStateName, AuthSlice } from "./state/auth.state";
import { stateName as countryStateName, CountrySlice } from "./state/country.state";
import { stateName as recipientStateName, RecipientSlice } from "./state/recipient.state";
import { stateName as transactionStateName, TransactionSlice } from "./state/transaction.state";

import { stateName as currenciesStateName, CurrencySlice } from "./state/currency";
import { stateName as transferStateName, TransferSlice } from "./state/transfer";
import { stateName as languageStateName, LanguageSlice } from "./state/language.state";
import { stateName as transferTypeStateName, TransferTypeSlice } from "./state/transferType.state";
import { stateName as partnerStateName, PartnerSlice } from "./state/partner.state";
import { stateName as currencyExchangeStateName, CurrencyExchangeSlice } from "./state/currencyExchange";
import { stateName as sendingReasonStateName, SendingReasonSlice } from "./state/sendingReason.state";

export default combineReducers({
    [authStateName]: AuthSlice.reducer,
    [countryStateName]: CountrySlice.reducer,
    [recipientStateName]: RecipientSlice.reducer,
    [transactionStateName]: TransactionSlice.reducer,
    [currenciesStateName]: CurrencySlice.reducer,
    [transferStateName]: TransferSlice.reducer,
    [languageStateName]: LanguageSlice.reducer,
    [transferTypeStateName]: TransferTypeSlice.reducer,
    [partnerStateName]: PartnerSlice.reducer,
    [currencyExchangeStateName]: CurrencyExchangeSlice.reducer,
    [sendingReasonStateName]: SendingReasonSlice.reducer,
});
