import axios, { AxiosResponse } from 'axios';
import { IUserLocation } from 'src/models/country';

const BASE_URL = 'https://34.67.21.215/api/v1/';
export interface INetworkResponse {
  data: any;
  error: any | string;
}

export const axiosPostRequest = (
  url: string,
  data: any,
  token?: string
): Promise<INetworkResponse> => {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  return axiosResponseWrapper(
    axios.post(`${BASE_URL}${url}`, data, {
      headers
    })
  );
};

export const axiosPatchRequest = (
  url: string,
  data: any,
  token?: string
): Promise<INetworkResponse> => {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  return axiosResponseWrapper(
    axios.patch(`${BASE_URL}${url}`, data, {
      headers
    })
  );
};

export const formDataPostRequest = (
  url: string,
  data: FormData,
  token?: string
): Promise<INetworkResponse> => {
  const headers: any = { 'Content-Type': 'multipart/form-data', Accept: '*/*' };
  if (token) headers.Authorization = `Bearer ${token}`;
  return axiosResponseWrapper(
    axios({
      method: 'POST',
      url: `${BASE_URL}${url}`,
      data,
      headers
    })
  );
};

export const formDataPutRequest = (
  url: string,
  data: FormData,
  token?: string
): Promise<INetworkResponse> => {
  const headers: any = { 'Content-Type': 'multipart/form-data', Accept: '*/*' };
  if (token) headers.Authorization = `Bearer ${token}`;
  return axiosResponseWrapper(
    axios({
      method: 'PUT',
      url: `${BASE_URL}${url}`,
      data,
      headers
    })
  );
};

export const axiosGetRequest = (
  url: string,
  token?: string
): Promise<INetworkResponse> => {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  return axiosResponseWrapper(
    axios.get(`${BASE_URL}${url}`, {
      headers
    })
  );
};

export const getLocationRequest = (): Promise<{
  data: null | IUserLocation;
  error: any;
}> => {
  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };
  return axiosResponseWrapper(
    axios.get(`https://ipapi.co/json/`, {
      headers
    })
  );
};

export const axiosResponseWrapper = (
  request: Promise<AxiosResponse<any, any>>
): Promise<INetworkResponse> => {
  return new Promise<INetworkResponse>((resolve) => {
    if (navigator.onLine) {
      request
        .then((response) => {
          resolve({ data: response.data, error: null });
        })
        .catch((error) => {
          if (error.code === 'ERR_NETWORK') {
            resolve({
              error: error.message?.error ?? error.message,
              data: null
            });
          } else if (!error.response) {
            resolve({ error: 'Error While Loading Data', data: null });
          } else {
            resolve({
              data: null,
              error:
                error?.response?.data?.error ??
                error?.response?.data ??
                error?.response ??
                error
            });
          }
        });
    } else {
      resolve({ data: null, error: "You're not online" });
    }
  });
};
