import { createSlice } from '@reduxjs/toolkit';
import { ILanguage } from 'src/models/language';

export interface ILanguageState {
  languages: ILanguage[];
}

const initialState: ILanguageState = {
  languages: []
};

const stateName = 'languages';

const LanguageSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setLanguage: (state, { payload }: { payload: ILanguage[] }) => {
      state.languages = payload;
    }
  }
});

///// Export state values ////////
export const selectLanguages = (state: any) =>
  (state.entities[stateName] as ILanguageState).languages ?? [];

///// Export actions ///////
export const { setLanguage } = LanguageSlice.actions;

export { stateName, LanguageSlice };
