import { createSlice } from "@reduxjs/toolkit";
import { ITransferType } from "src/models/transferType";

export interface IPayload {
    payload: {
        data: ITransferType[];
    };
}

export interface ITransferTypeState {
    transferTypes: ITransferType[];
}

const initialState: ITransferTypeState = {
    transferTypes: [],
};

const stateName = "transferTypes";

const TransferTypeSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setTransferTypes: (state, { payload }: IPayload) => {
            payload.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
            state.transferTypes = payload.data;
        },
    },
});

///// Export state values ////////
export const selectTransferTypes = (state: any) => (state.entities[stateName] as ITransferTypeState).transferTypes;

///// Export actions ///////
export const { setTransferTypes } = TransferTypeSlice.actions;

export { stateName, TransferTypeSlice };
