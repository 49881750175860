import { createSlice } from "@reduxjs/toolkit";
import { IPartner, IPartnerAccount } from "src/models/partner";
import { ICurrency, ITransferState, ICurrencyStateInput } from "src/models/currency";
import { ICurrencyExchangeRate } from "src/models/currencyExchange";
import { IRecipient } from "src/models/recipient";
import { ITransferType } from "src/models/transferType";

const initialState: ITransferState = {
    currencyFrom: { value: "", currency: {} as ICurrency },
    currencyTo: { value: "", currency: {} as ICurrency },
    currencyExchange: null,
    getExchangeRate: 0,
    recipient: null,
    partner: null,
    partnerAccount: null,
    recipientNotification: null,

    tab: 1,
    addRecipientModal: false,
    recipientNotificationModal: false,
    transferTypeModal: false,
    partnerModal: false,
    partnerAccountModal: false,
    transferType: null,
    recipientListModal: false,
};

const stateName = "transfer";

const TransferSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setCurrencyFromAndTo: (
            state,
            {
                payload,
            }: {
                payload: {
                    currencyFrom: ICurrencyStateInput;
                    currencyTo: ICurrencyStateInput;
                    currencyExchange: ICurrencyExchangeRate;
                };
            }
        ) => {
            state.currencyFrom = payload.currencyFrom;
            state.currencyTo = payload.currencyTo;
            state.currencyExchange = payload.currencyExchange;
        },
        setCurrencyFromValue: (state, { payload }: { payload: string }) => {
            state.currencyFrom.value = payload;
        },
        setCurrencyFromCurrency: (state, { payload }: { payload: ICurrency }) => {
            state.currencyFrom.currency = payload;
            if (state.currencyFrom.value.length > 0 && state.currencyFrom.currency?.id && state.currencyTo.currency?.id)
                state.getExchangeRate = state.getExchangeRate + 1;
        },
        setCurrencyToCurrency: (state, { payload }: { payload: ICurrency }) => {
            state.currencyTo.currency = payload;
            if (state.currencyFrom.value.length > 0 && state.currencyFrom.currency?.id && state.currencyTo.currency?.id)
                state.getExchangeRate = state.getExchangeRate + 1;
        },
        setCurrencyExchange: (state, { payload }: { payload: ICurrencyExchangeRate }) => {
            state.currencyExchange = payload;

            state.currencyTo.value = payload.total.toString();
        },
        setRecipient: (state, { payload }: { payload: IRecipient }) => {
            state.recipient = payload;
        },
        setPartner: (state, { payload }: { payload: IPartner }) => {
            state.partner = payload;
        },
        setPartnerAccount: (state, { payload }: { payload: IPartnerAccount }) => {
            state.partnerAccount = payload;
        },
        setRecipientNotification: (state, { payload }: { payload: boolean }) => {
            state.recipientNotification = payload;
        },
        setDeliveryMethod: (state, { payload }: { payload: ITransferType }) => {
            state.transferType = payload;
        },
        resetTransfer: (state) => {
            state.tab = 1;
            state.recipient = null;
            state.transferType = initialState.transferType;
            state.partner = initialState.partner;
            state.partnerAccount = initialState.partnerAccount;
        },

        resetTransfer1: (state) => {
            state.recipient = null;
        },

        gotoNextTab: (state) => {
            state.partnerModal = false;
            state.partnerAccountModal = false;
            state.transferTypeModal = false;
            state.recipientListModal = false;
            state.addRecipientModal = false;
            state.recipientNotificationModal = false;
            if (state.tab !== 3) {
                ++state.tab;
            }
        },
        gotoPreviousTab: (state) => {
            if (state.tab > 1 && state.tab < 4) {
                state.partnerModal = false;
                state.partnerAccountModal = false;
                state.transferTypeModal = false;
                state.addRecipientModal = false;
                state.recipientListModal = false;
                state.recipientNotificationModal = false;
                --state.tab;
            }
        },
        openAddRecipientModal: (state) => {
            state.addRecipientModal = true;
        },
        openRecipientNotificationModal: (state) => {
            state.recipientNotificationModal = true;
        },
        openTransferTypeModal: (state) => {
            state.transferTypeModal = true;
        },
        openPartnerModal: (state) => {
            state.partnerModal = true;
        },
        openPartnerAccountModal: (state) => {
            state.partnerAccountModal = true;
        },
        openRecipientListModal: (state) => {
            state.recipientListModal = true;
        },
        closeRecipientListModal: (state) => {
            state.recipientListModal = false;
        },
        closeRecipientModal: (state) => {
            state.addRecipientModal = false;
        },
        closeRecipientNotificationModal: (state) => {
            state.recipientNotificationModal = false;
        },
        closeTransferTypeModal: (state) => {
            state.transferTypeModal = false;
        },
        closePartnerModal: (state) => {
            state.partnerModal = false;
        },
        closePartnerAccountModal: (state) => {
            state.partnerAccountModal = false;
        },
    },
});

///// Export state values ////////
export const selectPartner = (state: any) => (state.entities[stateName] as ITransferState).partner;

export const selectTransferTypeAccount = (state: any) => (state.entities[stateName] as ITransferState).partnerAccount;
export const selectTransferCurrencyFrom = (state: any): ICurrencyStateInput => (state.entities[stateName] as ITransferState).currencyFrom;
export const selectTransferCurrencyTo = (state: any): ICurrencyStateInput => (state.entities[stateName] as ITransferState).currencyTo;
export const selectTransferCurrencyExchange = (state: any) => (state.entities[stateName] as ITransferState).currencyExchange;
export const selectRecipientNotification = (state: any) => (state.entities[stateName] as ITransferState).recipientNotification;
export const selectTransferFetchExchangeRate = (state: any): number => (state.entities[stateName] as ITransferState).getExchangeRate;
export const selectTransferType = (state: any) => (state.entities[stateName] as ITransferState).transferType;
export const selectTransferRecipient = (state: any) => (state.entities[stateName] as ITransferState).recipient;
export const selectAddRecipientModal = (state: any) => (state.entities[stateName] as ITransferState).addRecipientModal;
export const selectRecipientNotificationModal = (state: any) => (state.entities[stateName] as ITransferState).recipientNotificationModal;
export const selectTransferTypeModal = (state: any) => (state.entities[stateName] as ITransferState).transferTypeModal;
export const selectRecipientListModal = (state: any) => (state.entities[stateName] as ITransferState).recipientListModal;
export const selectPartnerModal = (state: any) => (state.entities[stateName] as ITransferState).partnerModal;
export const selectPartnerAccountModal = (state: any) => (state.entities[stateName] as ITransferState).partnerAccountModal;
export const selectCurrentTab = (state: any) => (state.entities[stateName] as ITransferState).tab;

///// Export actions ///////
export const {
    setCurrencyFromAndTo,
    resetTransfer,
    setCurrencyFromCurrency,
    setCurrencyFromValue,
    setCurrencyExchange,
    setCurrencyToCurrency,
    setRecipient,

    setDeliveryMethod,
    setRecipientNotification,
    resetTransfer1,
    closePartnerModal,
    closePartnerAccountModal,
    closeTransferTypeModal,
    closeRecipientModal,
    closeRecipientNotificationModal,
    openPartnerModal,
    openPartnerAccountModal,
    openTransferTypeModal,
    openAddRecipientModal,
    openRecipientNotificationModal,
    gotoNextTab,
    gotoPreviousTab,
    closeRecipientListModal,
    openRecipientListModal,
    setPartner,
    setPartnerAccount,
} = TransferSlice.actions;

export { stateName, TransferSlice };
