import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut, selectIsLoggedIn, setAuth } from "src/store/state/auth.state";
import { AuthRoutes, RouteName } from "src/constants/routeName";
import {
    getAccessToken,
    getRefreshTime,
    getRefreshToken,
    removeAccessToken,
    removeRefreshTime,
    removeRefreshToken,
    storeAccessToken,
    storeRefreshTime,
    storeRefreshToken,
} from "src/storage";
import { fetchProfileAction, refreshTokenAction } from "src/api/auth";
import { useNotificationContext } from "src/contexts/NotificationContext";
import { useRefreshCronjobContext } from "src/contexts/RefreshCronjobContext";
import SuspenseLoader from "../Loaders/SuspenseLoader";

interface IAutoLoadProps {
    children: React.ReactNode;
}

export default function AutoLoad({ children }: IAutoLoadProps) {
    const [loading, setLoading] = useState(true);
    const { startCronjob } = useRefreshCronjobContext();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const { showErrorNotification } = useNotificationContext();

    // check each time route changes
    useEffect(() => {
        checkUserToken();
        // eslint-disable-next-line
    }, []);

    // check if user is authenticated
    useEffect(() => {
        if (!AuthRoutes.includes(location.pathname) && !isLoggedIn) if (!loading) clearStorageAndGoToLogin();
        // eslint-disable-next-line
    }, [location.pathname]);

    const checkUserToken = async () => {
        if (AuthRoutes.includes(location.pathname)) setLoading(false);
        else {
            // check if refresh time,token and refresh exist on storage
            const refreshTime = getRefreshTime();
            const token = getAccessToken();
            const refreshToken = getRefreshToken();
            if (refreshTime?.length > 0 && token?.length > 0 && refreshToken?.length > 0) {
                // try to refresh token
                const { data } = await refreshTokenAction();

                if (data?.access_token && data?.refresh_token) {
                    const date = new Date();
                    date.setMinutes(date.getMinutes() + 9);
                    storeRefreshTime(String(date.getTime()));
                    storeRefreshToken(data.refresh_token);
                    storeAccessToken(data.access_token);
                    const { data: response, error } = await fetchProfileAction();
                    if (response) {
                        dispatch(
                            setAuth({
                                data: {
                                    user: response,
                                    refreshToken: data.refresh_token,
                                    token: data.access_token,
                                },
                            })
                        );
                        startCronjob();
                    } else {
                        showErrorNotification(error.message ?? "Token Expired please login");
                        clearStorageAndGoToLogin();
                    }
                } else clearStorageAndGoToLogin();
            } else clearStorageAndGoToLogin();
            setTimeout(() => setLoading(false), 500);
        }
    };

    // clear storage and navigate to login
    const clearStorageAndGoToLogin = () => {
        removeRefreshTime();
        removeRefreshToken();
        removeAccessToken();
        dispatch(logOut());
        navigate(RouteName.Login, {
            state: { from: location.pathname + location.search },
        });
    };

    return <> {loading ? <SuspenseLoader /> : children} </>;
}
