import store from 'store';

// local storage keys
export enum EStorage {
  accessToken = 'access_token',
  refreshToken = 'refresh_token',
  refreshTime = 'refresh_time'
}

export const storeAccessToken = (value: string) =>
  storeSet(EStorage.accessToken, value);

export const getAccessToken = (): string =>
  storeGet(EStorage.accessToken, null);

export const removeAccessToken = () => storeRemove(EStorage.accessToken);

export const storeRefreshToken = (value: string) =>
  storeSet(EStorage.refreshToken, value);

export const getRefreshToken = (): string =>
  storeGet(EStorage.refreshToken, null);

export const removeRefreshToken = () => storeRemove(EStorage.refreshToken);

export const storeRefreshTime = (value: string) =>
  storeSet(EStorage.refreshTime, value);

export const getRefreshTime = (): string =>
  storeGet(EStorage.refreshTime, null);

export const removeRefreshTime = () => storeRemove(EStorage.refreshTime);

// get data form storage wrapper
const storeGet = (key: string, defaultValue?: any) => {
  try {
    return store.get(key, defaultValue);
  } catch {
    store.remove(key);
    return defaultValue;
  }
};

// set data to storage wrapper
const storeSet = (key: string, value: any) => {
  try {
    store.set(key, value);
  } catch {
    store.remove(key);
  }
};

// remove data to storage wrapper
const storeRemove = (key: string) => {
  try {
    store.remove(key);
  } catch {
    store.remove(key);
  }
};
