import { createSlice } from "@reduxjs/toolkit";
import { ISendingReason, ISendingReasonState } from "src/models/sendingReason";

const initialState: ISendingReasonState = {
    sendingReasons: [],
    reload: 0,
};

const stateName = "sendingReasons";

const SendingReasonSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setSendingReasons: (state, { payload }: { payload: { data: ISendingReason[] } }) => {
            state.sendingReasons = payload.data;
        },
    },
});

///// Export state values ////////
export const selectSendingReasons = (state: any) => (state.entities[stateName] as ISendingReasonState).sendingReasons;

///// Export actions ///////
export const { setSendingReasons } = SendingReasonSlice.actions;

export { stateName, SendingReasonSlice };
