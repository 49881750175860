import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouteName } from "src/constants/routeName";

import editProfile from "src/assets/images/edit_profile.svg";
import editProfileBlack from "src/assets/images/edit_profile_black.svg";
import gift from "src/assets/images/gift.svg";
import giftBlack from "src/assets/images/gift_black.svg";
import logout from "src/assets/images/logout.svg";
import logoutBlack from "src/assets/images/logout_black.svg";
import about from "src/assets/images/about.svg";
import aboutBlack from "src/assets/images/about_black.svg";
import legal from "src/assets/images/shield.svg";
import legalBlack from "src/assets/images/shield_black.svg";
import setting from "src/assets/images/Icon ionic-md-settings.svg";
import settingBlack from "src/assets/images/Icon ionic-md-settings_black.svg";
import help from "src/assets/images/help-web-button.svg";
import helpBlack from "src/assets/images/help-web-button_black.svg";
import { TbChevronRight } from "react-icons/tb";
import { useState } from "react";
import Model from "src/components/Modal";
import { logoutAction } from "src/api/auth";
import { useDispatch } from "react-redux";
import { logOut } from "src/store/state/auth.state";
import { useRefreshCronjobContext } from "src/contexts/RefreshCronjobContext";

export default function AccountLayout() {
    const [openSignOut, setOpenSignOut] = useState<boolean>(false);
    const path = useLocation().pathname;
    const handleOpenSignOut = () => setOpenSignOut(true);
    const handleCloseSignOut = () => setOpenSignOut(false);

    return (
        <div className="w-full max-w-[1280px] m-auto px-3">
            <div className="w-full flex smx:gap-x-8 relative">
                <div className="mt-24 text-[#3C3C3C] font-medium hidden smx:block text-sm w-48 fixed">
                    <NavLink
                        to={RouteName.Profile}
                        className={({ isActive }) => `flex gap-x-3 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={editProfile} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.Profile ? (
                            <img alt="" src={editProfile} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={editProfileBlack} className="h-5 w-5 block lg:hidden" />
                        )}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Profile info</div>
                            {path === RouteName.Profile && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <NavLink
                        to={RouteName.Referral}
                        className={({ isActive }) => `flex gap-x-3 mt-4 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={gift} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.Referral ? (
                            <img alt="" src={gift} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={giftBlack} className="h-5 w-5 block lg:hidden" />
                        )}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Refer and earn $20</div>
                            {path === RouteName.Referral && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <NavLink
                        to={RouteName.Help}
                        className={({ isActive }) => `flex gap-x-3 mt-4 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={help} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.Help ? (
                            <img alt="" src={help} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={helpBlack} className="h-5 w-5 block lg:hidden" />
                        )}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Help center</div>
                            {path === RouteName.Help && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <NavLink
                        to={RouteName.Setting}
                        className={({ isActive }) => `flex gap-x-3 mt-4 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={setting} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.Setting ? (
                            <img alt="" src={setting} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={settingBlack} className="h-5 w-5 block lg:hidden" />
                        )}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Setting</div>
                            {path === RouteName.Setting && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <NavLink
                        to={RouteName.Legal}
                        className={({ isActive }) => `flex gap-x-3 mt-4 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={legal} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.Legal ? (
                            <img alt="" src={legal} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={legalBlack} className="h-5 w-5 block lg:hidden" />
                        )}{" "}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Legal</div>
                            {path === RouteName.Legal && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <NavLink
                        to={RouteName.AccountAbout}
                        className={({ isActive }) => `flex gap-x-3 mt-4 items-center ${isActive && " text-[#019267] "}`}>
                        <img src={about} alt="" className="h-4 w-4 lg:block hidden" />
                        {path === RouteName.AccountAbout ? (
                            <img alt="" src={about} className="h-6 w-6 block lg:hidden" />
                        ) : (
                            <img alt="" src={aboutBlack} className="h-5 w-5 block lg:hidden" />
                        )}
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>About</div>
                            {path === RouteName.AccountAbout && <TbChevronRight className="text-[#019267]" />}
                        </div>
                    </NavLink>
                    <button onClick={handleOpenSignOut} className={`flex gap-x-3 mt-4 items-center `}>
                        <img src={logout} alt="" className="h-4 w-4 lg:block hidden" />
                        <img alt="" src={logoutBlack} className="h-6 w-6 block lg:hidden" />
                        <div className="lg:flex hidden gap-x-3 items-center">
                            <div>Sign out</div>
                        </div>
                    </button>
                    <LogoutModal handleClose={handleCloseSignOut} open={openSignOut} />
                </div>
                <div className=" lg:w-48 smx:w-10" />
                <div className="text-start lg:mt-16 mt-2 w-full">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export function LogoutModal({ open, handleClose }: { open: boolean; handleClose: () => void }) {
    const { stopCronjob } = useRefreshCronjobContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSignOut = async () => {
        setLoading(true);
        const { data } = await logoutAction();
        setLoading(false);
        if (data) {
            stopCronjob();
            dispatch(logOut());
            navigate(RouteName.Login, { replace: true });
        }
    };

    return (
        <Model open={open} handleClose={handleClose} title="Sign out" centered>
            <div className="w-full">
                <div className="w-full text-center">Are you sure you want to sign out?</div>
                <div className="w-full mt-6">
                    <div className="flex mt-6 gap-x-4">
                        <button
                            onClick={handleClose}
                            className="bg-[#019267] hover:opacity-70 font-bold text-base p-3 rounded-[4px] text-white w-full">
                            No, thanks
                        </button>
                        <button
                            disabled={loading}
                            onClick={handleSignOut}
                            className="disabled:bg-[#efefef] disabled:cursor-not-allowed border-[#979797] hover:bg-slate-100 border padding-3 rounded-[4px] text-base font-bold text-[#979797] w-full">
                            Yes, signout
                        </button>
                    </div>
                </div>
            </div>
        </Model>
    );
}
