export const RouteName = {
    Welcome: "/",
    FAQ: "/faq",
    About: "/about",
    PrivacyPolicy: "/privacy-policy",
    Cookie: "/cookie",
    TermAndCondition: "/term-condition",
    Login: "/login",
    VerifyCode: "/verify-code",
    Register: "/sign-up",
    ForgetPassword: "/forget-password",
    ResetPassword: "/reset-password",
    Home: "/home",
    Transfer: "/transfer",
    Checkout: "/checkout/:id",
    Recipient: "/recipient",
    RecipientDetail: "/recipient/:id",
    History: "/history",
    Profile: "/account/profile",
    Referral: "/account/referral",
    AccountAbout: "/account/about",
    Help: "/account/help",
    Legal: "/account/legal",
    Setting: "/account/setting",
    FaceId: "/account/face-id",
    Logout: "/account/logout",
    HistoryDetail: "/history/:id",
};

export const AccountRoutes: string[] = [
    RouteName.Profile,
    RouteName.Referral,
    RouteName.Help,
    RouteName.Legal,
    RouteName.AccountAbout,
    RouteName.FaceId,
    RouteName.Setting,
    RouteName.Logout,
];

export const AuthRoutes: string[] = [
    RouteName.Login,
    RouteName.Register,
    RouteName.Welcome,
    RouteName.About,
    RouteName.VerifyCode,
    RouteName.FAQ,
    RouteName.PrivacyPolicy,
    RouteName.Cookie,
    RouteName.TermAndCondition,
    RouteName.ForgetPassword,
    RouteName.ResetPassword,
];
