import { createSlice } from "@reduxjs/toolkit";
import { IReceiverCountry } from "src/models/country";

interface ICurrencyState {
    receivingCurrency: IReceiverCountry[];
    sendingCurrency: IReceiverCountry[];
}

const initialState: ICurrencyState = {
    receivingCurrency: [],
    sendingCurrency: [],
};

const stateName = "currencies";

const CurrencySlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setReceivingCurrencies: (state, { payload }: { payload: IReceiverCountry[] }) => {
            state.receivingCurrency = payload;
        },
        setSendingCurrencies: (state, { payload }: { payload: IReceiverCountry[] }) => {
            state.sendingCurrency = payload;
        },
    },
});

///// Export state values ////////
export const selectSendingCurrencies = (state: any) => (state.entities[stateName] as ICurrencyState).sendingCurrency;
export const selectReceivingCurrencies = (state: any) => (state.entities[stateName] as ICurrencyState).receivingCurrency;
///// Export actions ///////
export const { setReceivingCurrencies, setSendingCurrencies } = CurrencySlice.actions;

export { stateName, CurrencySlice };
