import { Outlet, useLocation } from "react-router";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
import { RouteName } from "src/constants/routeName";

export default function BaseLayout() {
    const path = useLocation().pathname;

    return (
        <div className={`w-full`}>
            <div className={`max-w-[1280px] w-full ${path !== RouteName.Welcome ? "max-w-[1280px]" : "max-w-full"} mx-auto`}>
                {path !== RouteName.Welcome && <Header />}
                <main className="w-full min-h-[calc(100vh-76px)]">
                    <Outlet />
                </main>
            </div>
            <div className="w-full max-w-[1280px] mx-auto">
                {path !== RouteName.Login &&
                    path !== RouteName.Register &&
                    path !== RouteName.ForgetPassword &&
                    path !== RouteName.ResetPassword && <Footer />}
            </div>
        </div>
    );
}
