import { notification } from "antd";
import { useState, createContext, useEffect, useContext, ReactNode } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

interface IInput {
    open: boolean;
    message: string | null;
    type: "error" | "success";
}
type INotificationContext = {
    showErrorNotification: (value: string) => void;
    showSuccessNotification: (value: string) => void;
};

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
    const [api, contextHolder] = notification.useNotification();
    const [input, setInput] = useState<IInput>({
        open: false,
        message: null,
        type: "error",
    });

    useEffect(() => {
        if (input.open && input.message) showNotification();
        // eslint-disable-next-line
    }, [input]);

    const showErrorNotification = (value: string) => setInput({ open: true, message: value, type: "error" });
    const showSuccessNotification = (value: string) => setInput({ open: true, message: value, type: "success" });

    const showNotification = () =>
        api.open({
            placement: "topRight",
            message: null,
            closeIcon: null,
            style: { padding: 0, top: 44 },
            icon: null,
            description: (
                <div
                    className={`w-full rounded-lg ${
                        input.type === "success" ? "bg-[#019267]" : "bg-[#DF7861]"
                    } py-3 font-semibold text-base text-white px-3 flex gap-x-2 items-center justify-between`}>
                    <div>{input.message}</div>
                    <button onClick={() => api.destroy()}>
                        <IoCloseCircleOutline className="text-black w-6 h-6" />
                    </button>
                </div>
            ),
        });

    return (
        <NotificationContext.Provider value={{ showErrorNotification, showSuccessNotification }}>
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
};
