import { createContext, useEffect, useContext, useState, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { refreshTokenAction } from "src/api/auth";
import { logOut, setUserCredentials } from "src/store/state/auth.state";
import { useNotificationContext } from "./NotificationContext";
import { RouteName } from "src/constants/routeName";
import { removeRefreshTime, removeRefreshToken, removeAccessToken } from "src/storage";
import { useLocation, useNavigate } from "react-router";
import CronJob from "cron";
type IRefreshCronjobContext = {
    startCronjob: () => void;
    stopCronjob: () => void;
};

export const useRefreshCronjobContext = () => useContext(RefreshCronjobContext);

export const RefreshCronjobContext = createContext<IRefreshCronjobContext>({} as IRefreshCronjobContext);

export const RefreshCronjobProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { showErrorNotification } = useNotificationContext();

    useEffect(() => {}, []);

    const startCronjob = () => {
        cronJob.start();
    };
    const stopCronjob = () => {
        cronJob.stop();
    };

    // refresh token
    const refreshMyToken = async () => {
        const { data } = await refreshTokenAction();
        if (data?.access_token && data?.refresh_token) {
            dispatch(
                setUserCredentials({
                    token: data.access_token,
                    refreshToken: data.refresh_token,
                })
            );
        } else {
            navigate(RouteName.Login, {
                state: { from: location.pathname + location.search },
            });
            showErrorNotification("Token Expired please login");
            removeRefreshTime();
            removeRefreshToken();
            removeAccessToken();
            dispatch(logOut());
            cronJob.stop();
            return "false";
        }
    };

    const [cronJob] = useState(new CronJob.CronJob("0 */9 * * * *", refreshMyToken));

    return <RefreshCronjobContext.Provider value={{ stopCronjob, startCronjob }}>{children}</RefreshCronjobContext.Provider>;
};
