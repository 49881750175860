import {
    IAuthResponse,
    IEditAccountPasswordInput,
    IForgetPasswordInput,
    ILoginInput,
    IResetPassword,
    ISignUpInput,
    IUser,
} from "src/models/auth";
import { axiosGetRequest, axiosPostRequest, axiosPatchRequest } from ".";
import { getAccessToken, getRefreshToken } from "src/storage";

export const loginAction = async (data: ILoginInput): Promise<{ data: null | IAuthResponse; error: any }> => {
    return axiosPostRequest("auth/login", data);
};

export const verifyCodeAction = async (email: string, code: string, verificationType: string): Promise<{ data: any; error: any }> => {
    return axiosGetRequest(`auth/verify-code?verification_type=${verificationType}&email=${email}&code=${code}`);
};

export const logoutAction = async (): Promise<{
    data: null | IAuthResponse;
    error: any;
}> => {
    return axiosPostRequest("auth/logout", {
        refresh_token: getRefreshToken(),
        access_token: getAccessToken(),
    });
};
export const registerAction = async (data: ISignUpInput): Promise<{ data: null | IAuthResponse; error: any }> => {
    return axiosPostRequest("auth/register", {
        ...data,
        repeat_password: data.password,
    });
};

export const forgetPasswordAction = async (data: IForgetPasswordInput) => {
    return axiosPostRequest("auth/forgot-password", data);
};

export const resetPasswordAction = async (data: IResetPassword) => {
    return axiosPostRequest("auth/reset-password", data);
};
export const changePasswordAction = async (data: IEditAccountPasswordInput) => {
    return axiosPostRequest("profile/change-password", data, getAccessToken());
};

export const fetchProfileAction = async (): Promise<{
    data: IUser | null;
    error: any;
}> => {
    return axiosGetRequest("profile", getAccessToken());
};

export const updateProfileAction = async (
    data: IUser
): Promise<{
    data: IUser | null;
    error: any;
}> => {
    const input: any = { ...data };
    if (input.created_at) delete input.created_at;
    if (input.updated_at) delete input.updated_at;
    if (input.id) delete input.id;
    if (input.edges) delete input.edges;
    return axiosPatchRequest("profile", input, getAccessToken());
};

export const refreshTokenAction = async () => {
    return axiosPostRequest("auth/refresh", {
        access_token: getAccessToken(),
        refresh_token: getRefreshToken(),
    });
};
