import { FC, ReactNode } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import { RouteName } from "src/constants/routeName";

interface HeaderLayoutProps {
    children?: ReactNode;
}

const HeaderLayout: FC<HeaderLayoutProps> = () => {
    const location = useLocation();
    return (
        <div className="bg-white h-screen w-full font-dm-sans">
            <Header />
            <div
                className={`w-full h-[calc(100%-61.6px)] overflow-auto flex ${
                    location.pathname !== RouteName.About &&
                    location.pathname !== RouteName.FAQ &&
                    location.pathname !== RouteName.Welcome &&
                    "mountain-bg"
                }`}>
                <div className="w-full grow">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default HeaderLayout;
