import { createSlice } from '@reduxjs/toolkit';
import { ICurrencyExchange } from 'src/models/currencyExchange';

interface ICurrencyExchangeState {
  currencyExchanges: ICurrencyExchange[];
}

const initialState: ICurrencyExchangeState = {
  currencyExchanges: []
};

const stateName = 'currency_exchanges';

const CurrencyExchangeSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    appendCurrencyExchanges: (
      state,
      { payload }: { payload: ICurrencyExchange }
    ) => {
      state.currencyExchanges.push(payload);
      setTimeout(() => {
        state.currencyExchanges = [];
      }, 1000 * 60 * 60 * 25);
    }
  }
});

///// Export state values ////////
export const selectCurrencyExchanges = (state: any) =>
  (state.entities[stateName] as ICurrencyExchangeState).currencyExchanges;
///// Export actions ///////
export const { appendCurrencyExchanges } = CurrencyExchangeSlice.actions;

export { stateName, CurrencyExchangeSlice };
