import { createSelector, createSlice } from "@reduxjs/toolkit";
import { IAirtimePartner, IPartnerBank, IPartner, IMobileWalletPartner, ITransferTypeTYPE as IPartnerType } from "src/models/partner";

export interface IPartnerState {
    bankPartners: IPartnerBank[];
    airtimePartners: IAirtimePartner[];
    mobileWalletPartners: IMobileWalletPartner[];
    type: IPartnerType;
}

const initialState: IPartnerState = {
    type: null,
    bankPartners: [],
    airtimePartners: [],
    mobileWalletPartners: [],
};

const stateName = "partners";

const PartnerSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setBankPartners: (state, { payload }: { payload: { data: IPartnerBank[] } }) => {
            state.bankPartners = payload.data;
            state.type = "BANK_DEPOSIT";
        },
        setAirtimePartners: (state, { payload }: { payload: { data: IAirtimePartner[] } }) => {
            state.airtimePartners = payload.data;
            state.type = "AIRTIME";
        },
        setMobileWalletPartners: (state, { payload }: { payload: { data: IMobileWalletPartner[] } }) => {
            state.mobileWalletPartners = payload.data;
            state.type = "MOBILE_MONEY";
        },
    },
});

///// Export state values ////////
// export const selectPartners = (
//     state: any
// ): {
//     type: IPartnerType;
//     data: IPartner[];
// } => {
//     const data = state.entities[stateName] as IPartnerState;
//     const type = data.type;
//     if (type === "MOBILE_MONEY") return { type, data: data.mobileWalletPartners };
//     else if (type === "AIRTIME") return { type, data: data.airtimePartners };
//     else if (type === "BANK_DEPOSIT") return { type, data: data.bankPartners };
//     else return { type, data: [] };
// };
const selectPartnerState = (state: any) => state.entities[stateName] as IPartnerState;

export const selectPartners = createSelector([selectPartnerState], (data: IPartnerState): { type: IPartnerType; data: IPartner[] } => {
    const type = data.type;
    if (type === "MOBILE_MONEY") return { type, data: data.mobileWalletPartners };
    else if (type === "AIRTIME") return { type, data: data.airtimePartners };
    else if (type === "BANK_DEPOSIT") return { type, data: data.bankPartners };
    else return { type, data: [] };
});

///// Export actions ///////
export const { setBankPartners, setAirtimePartners, setMobileWalletPartners } = PartnerSlice.actions;

export { stateName, PartnerSlice };
