import { Link } from "react-router-dom";
import logo from "src/assets/images/logo.png";
import appStore from "src/assets/images/Mobile app store badge.svg";
import googlePlay from "src/assets/images/Mobile app store badge (1).svg";
import twitter from "src/assets/images/twitter.svg";
import linkedin from "src/assets/images/linkedin.svg";
import facebook from "src/assets/images/facebook.svg";
import { RouteName } from "src/constants/routeName";

export default function Footer() {
    return (
        <footer className="w-full px-8 py-12">
            <div className="w-full flex flex-col items-center md:items-start md:flex-row">
                <div className="w-full flex flex-col items-center md:items-start">
                    <img src={logo} alt="logo" height={32.3} width={80.36} className=" h-[32.3px] w-[80.36px]" />
                    <p className="mt-6 text[#475467] font-normal text-base md:max-w-[320px]">
                        Design amazing digital experiences that create more happy in the world.
                    </p>
                    <div className="mt-8 flex gap-x-8 text-[#475467] font-semibold text-base w-full">
                        <div className="flex flex-col gap-y-3 gap-x-8 md:flex-row">
                            <Link to={RouteName.TermAndCondition} className="nav-hover">
                                Terms and Conditions
                            </Link>
                            <Link to={RouteName.PrivacyPolicy} className="nav-hover">
                                Privacy Policy
                            </Link>
                            <Link to={RouteName.Cookie} className="nav-hover">
                                Cookies
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-y-3 md:items-start">
                    <div className="text-center text-secondary font-medium text-base mt-12 md:mt-0">Get the app</div>
                    <div className="flex gap-4 justify-center items-center md:flex-col">
                        <Link to={"/"}>
                            <img src={appStore} alt="app store" width={132} height={44} className="w-[132px] h-11 rounded-lg scale-hover" />
                        </Link>
                        <Link to={"/"}>
                            <img
                                src={googlePlay}
                                alt="google paly"
                                width={148.5}
                                height={44}
                                className="w-[148.5px] h-11 rounded-md scale-hover"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="w-full bg-[#b2b2b2] h-[0.4px] mt-14 mb-6"></div>
                <div className="flex flex-col md:items-end md:flex-row-reverse">
                    <div className="w-full flex gap-x-6 justify-start md:justify-end">
                        <Link to={"/"}>
                            <img src={twitter} alt="twitter" width={24} height={24} className="w-6 h-6" />
                        </Link>
                        <Link to={"/"}>
                            <img src={linkedin} alt="linkedin" width={24} height={24} className="w-6 h-6" />
                        </Link>
                        <Link to={"/"}>
                            <img src={facebook} alt="facebook" width={24} height={24} className="w-6 h-6" />
                        </Link>
                    </div>
                    <div className="w-full mt-6 text-[#667085] text-base font-normal">© 2023 Duruj, All rights reserved.</div>
                </div>
            </div>
        </footer>
    );
}
