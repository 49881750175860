import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { RouteName } from "src/constants/routeName";

export default function CookieContentComponent() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="duruj.cookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "4px", fontWeight: "bolder" }}>
            By continuing to browse the site, you're agreeing to our use of cookies. Learn more in our{" "}
            <Link className="text-[rgb(255,212,45)]" to={RouteName.PrivacyPolicy}>
                privacy policy
            </Link>
            .
        </CookieConsent>
    );
}
