import { createContext, ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLang } from "src/store/state/auth.state";

type ILanguageContext = {
    changeLang: (lang: ILanguage) => void;
};

type ILanguage = string;

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageContext = createContext<ILanguageContext>({} as ILanguageContext);

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
    const { i18n } = useTranslation();
    const userLang = useSelector(selectLang);

    useEffect(() => {
        changeLang(userLang as ILanguage);
        // eslint-disable-next-line
    }, [userLang]);

    const changeLang = (lang: ILanguage) => {
        i18n.changeLanguage(lang);
    };

    return (
        <LanguageContext.Provider
            value={{
                changeLang,
            }}>
            {children}
        </LanguageContext.Provider>
    );
};
