import { createSlice } from '@reduxjs/toolkit';
import { ITransaction } from 'src/models/transaction';

export interface IPayload {
  payload: {
    data: {
      transaction: ITransaction;
    };
  };
}

export interface ITransactionsPayload {
  payload: {
    data: ITransaction[];
  };
}

export interface ITransactionState {
  transactions: ITransaction[];
}

const initialState: ITransactionState = {
  transactions: []
};

const stateName = 'transactions';

const TransactionSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    appendTransaction(state, { payload }: { payload: ITransaction }) {
      state.transactions.push(payload);
    },
    setTransactions(state, { payload }: ITransactionsPayload) {
      state.transactions = payload.data;
    }
  }
});

export const selectTransactions = (state: any): ITransaction[] =>
  (state.entities[stateName] as ITransactionState).transactions;

///// Export actions ///////
export const { setTransactions, appendTransaction } = TransactionSlice.actions;

export { stateName, TransactionSlice };
