import { createSlice } from "@reduxjs/toolkit";
import { EStorageKeys } from "src/constants/enum";
import { IUser } from "src/models/auth";
import {
    removeAccessToken,
    removeRefreshTime,
    removeRefreshToken,
    storeAccessToken,
    storeRefreshTime,
    storeRefreshToken,
} from "src/storage";

export interface IAuthPayload {
    payload: {
        data: {
            user: IUser;
            token: string;
            refreshToken: string;
        };
    };
}

interface IAuthState {
    isLoggedIn: boolean;
    token: string | null;
    refreshToken: string | null;
    refreshTime: number | null;
    user: IUser | null;
    lang: string;
}

const initialState: IAuthState = {
    isLoggedIn: false,
    lang: "en",
    refreshToken: null,
    token: null,
    refreshTime: null,
    user: null,
};

const stateName = "auth";

const AuthSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setAuth: (state, { payload }: IAuthPayload) => {
            const date = new Date();
            date.setMinutes(date.getMinutes() + 8);
            state.user = payload.data.user;
            state.refreshTime = date.getTime();
            state.token = payload.data.token;
            state.isLoggedIn = true;
            storeRefreshTime(String(date.getTime()));
        },
        setUser: (state, { payload }: { payload: { data: IUser } }) => {
            state.user = payload.data;
        },
        setUserCredentials: (state, { payload }: { payload: { token: string; refreshToken: string } }) => {
            const date = new Date();
            date.setMinutes(date.getMinutes() + 8);
            state.token = payload.token;
            state.refreshTime = date.getTime();
            state.refreshToken = payload.refreshToken;
            storeRefreshTime(String(state.refreshTime));
            storeRefreshToken(state.refreshToken);
            storeAccessToken(state.token);
        },
        setUserLang: (state, { payload }: { payload: string }) => {
            state.lang = payload;
        },
        reset: (state) => {
            state.refreshTime = null;
            localStorage.removeItem(EStorageKeys.refreshTime);
        },
        logOut: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.refreshTime = null;
            state.refreshToken = null;
            state.token = null;
            // remove data from storage
            removeRefreshTime();
            removeAccessToken();
            removeRefreshToken();
        },
    },
});

///// Export state values ////////
export const selectAuthUser = (state: any) => (state.entities[stateName] as IAuthState).user;
export const selectIsLoggedIn = (state: any) => (state.entities[stateName] as IAuthState).isLoggedIn;

export const selectLang = (state: any) => (state.entities[stateName] as IAuthState).lang;

///// Export actions ///////
export const { setUser, setAuth, setUserCredentials, logOut, reset, setUserLang } = AuthSlice.actions;

export { stateName, AuthSlice };
