import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";
import ErrorBoundary from "src/components/ErrorBoundary";
import SuspenseLoader from "src/components/Loaders/SuspenseLoader";
import { RouteName } from "src/constants/routeName";
import AccountLayout from "src/layout/AccountLayout";
import HeaderLayout from "src/layout/HeaderLayout";

const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        </Suspense>
    );

////////////////////////////////////////////////////////////////////////////////
// start  pages
////////////////////////////////////////////////////////////////////////////////
const WelcomePage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Welcome")));
const AboutPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/About")));
const FAQPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/FAQ")));
const PrivacyPolicyPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/PrivacyPolicy")));
const CookiePage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Cookie")));
const TermAndConditionPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/TermAndCondition")));
const HomePage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Home")));
const TransferPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Transfer")));
const CheckoutPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Checkout")));
const RecipientPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Recipient")));
const RecipientDetailPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/RecipientDetail")));
const HistoryPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/History")));
const HistoryDetailPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/HistoryDetail")));
const ProfileInfoPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/ProfileInfo")));
const ReferralPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Referral")));
const HelpPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Help")));
const LegalPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Legal")));
const SettingPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/Setting")));
const AccountAboutPage = Loader(lazy(() => import("src/pages/AuthenticatedPage/About")));
////////////////////////////////////////////////////////////////////////////////
// end  pages
////////////////////////////////////////////////////////////////////////////////

// Status
const Status404 = Loader(lazy(() => import("src/pages/Status/Status404")));

////////////////////////////////////////////////////////////////////////////////
// start routes
////////////////////////////////////////////////////////////////////////////////
const AuthenticatedRoutes: RouteObject[] = [
    {
        path: "",
        element: <HeaderLayout />,
        children: [
            {
                path: RouteName.Welcome,
                element: <WelcomePage />,
            },
            {
                path: RouteName.About,
                element: <AboutPage />,
            },
            {
                path: RouteName.FAQ,
                element: <FAQPage />,
            },
            {
                path: RouteName.PrivacyPolicy,
                element: <PrivacyPolicyPage />,
            },
            {
                path: RouteName.Cookie,
                element: <CookiePage />,
            },
            {
                path: RouteName.TermAndCondition,
                element: <TermAndConditionPage />,
            },
            {
                path: RouteName.Home,
                element: <HomePage />,
            },
            {
                path: RouteName.Transfer,
                element: <TransferPage />,
            },
            {
                path: RouteName.Checkout,
                element: <CheckoutPage />,
            },
            {
                path: RouteName.Recipient,
                element: <RecipientPage />,
            },
            {
                path: RouteName.RecipientDetail,
                element: <RecipientDetailPage />,
            },
            {
                path: RouteName.History,
                element: <HistoryPage />,
            },
            {
                path: RouteName.HistoryDetail,
                element: <HistoryDetailPage />,
            },
            {
                path: "",
                element: <AccountLayout />,
                children: [
                    {
                        path: RouteName.Profile,
                        element: <ProfileInfoPage />,
                    },
                    {
                        path: RouteName.Referral,
                        element: <ReferralPage />,
                    },
                    {
                        path: RouteName.Help,
                        element: <HelpPage />,
                    },
                    {
                        path: RouteName.Legal,
                        element: <LegalPage />,
                    },
                    {
                        path: RouteName.Setting,
                        element: <SettingPage />,
                    },
                    {
                        path: RouteName.AccountAbout,
                        element: <AccountAboutPage />,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Status404 />,
    },
];

export default AuthenticatedRoutes;
