import "./index.css";

export default function SuspenseLoader() {
    return (
        <div className="w-full min-h-[calc(100vh-76px)] flex justify-center items-center">
            <div className="section">
                <div className="loader">
                    <span style={{ "--i": "1" } as any}></span>
                    <span style={{ "--i": "2" } as any}></span>
                    <span style={{ "--i": "3" } as any}></span>
                    <span style={{ "--i": "4" } as any}></span>
                    <span style={{ "--i": "5" } as any}></span>
                    <span style={{ "--i": "6" } as any}></span>
                    <span style={{ "--i": "7" } as any}></span>
                    <span style={{ "--i": "8" } as any}></span>
                    <span style={{ "--i": "9" } as any}></span>
                    <span style={{ "--i": "10" } as any}></span>
                    <span style={{ "--i": "11" } as any}></span>
                    <span style={{ "--i": "12" } as any}></span>
                    <span style={{ "--i": "13" } as any}></span>
                    <span style={{ "--i": "14" } as any}></span>
                    <span style={{ "--i": "15" } as any}></span>
                    <span style={{ "--i": "16" } as any}></span>
                    <span style={{ "--i": "17" } as any}></span>
                    <span style={{ "--i": "18" } as any}></span>
                    <span style={{ "--i": "19" } as any}></span>
                    <span style={{ "--i": "20" } as any}></span>
                </div>
            </div>
        </div>
    );
}
