import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";
import ErrorBoundary from "src/components/ErrorBoundary";
import SuspenseLoader from "src/components/Loaders/SuspenseLoader";
import { RouteName } from "src/constants/routeName";
import BaseLayout from "src/layout/BaseLayout";

const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        </Suspense>
    );

const WelcomePage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Welcome")));
const AboutPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/About")));
const FAQPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/FAQ")));
const PrivacyPolicyPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/PrivacyPolicy")));
const CookiePage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Cookie")));
const TermAndConditionPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/TermAndCondition")));

const LoginPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Auth/Login")));
const VerifyCodePage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Auth/VerifyCode")));
const SignUpPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Auth/SignUp")));
const ForgetPasswordPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Auth/ForgetPassword")));
const ResetPasswordPage = Loader(lazy(() => import("src/pages/UnAuthenticatedPage/Auth/ResetPassword")));

const UnauthenticatedRoutes: RouteObject[] = [
    {
        path: "",
        element: <BaseLayout />,
        children: [
            {
                path: RouteName.Login,
                element: <LoginPage />,
            },
            {
                path: RouteName.VerifyCode,
                element: <VerifyCodePage />,
            },
            {
                path: RouteName.Register,
                element: <SignUpPage />,
            },
            {
                path: RouteName.ForgetPassword,
                element: <ForgetPasswordPage />,
            },
            {
                path: RouteName.ResetPassword,
                element: <ResetPasswordPage />,
            },
            {
                path: RouteName.Welcome,
                element: <WelcomePage />,
            },
            {
                path: RouteName.About,
                element: <AboutPage />,
            },
            {
                path: RouteName.FAQ,
                element: <FAQPage />,
            },
            {
                path: RouteName.PrivacyPolicy,
                element: <PrivacyPolicyPage />,
            },
            {
                path: RouteName.Cookie,
                element: <CookiePage />,
            },
            {
                path: RouteName.TermAndCondition,
                element: <TermAndConditionPage />,
            },
        ],
    },
];

export default UnauthenticatedRoutes;
