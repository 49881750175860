import { createSlice } from '@reduxjs/toolkit';
import { ICountry, IReceiverCountry } from 'src/models/country';

export interface ICountryState {
  publicCountries: ICountry[];
  publicReceivingCountries: IReceiverCountry[];
}

const initialState: ICountryState = {
  publicCountries: [],
  publicReceivingCountries: []
};

const stateName = 'countries';

const CountrySlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setPublicCountry: (
      state,
      { payload }: { payload: { data: ICountry[] } }
    ) => {
      state.publicCountries = payload.data;
    },
    setPublicReceivingCountry: (
      state,
      { payload }: { payload: { data: IReceiverCountry[] } }
    ) => {
      state.publicReceivingCountries = payload.data;
    }
  }
});

///// Export state values ////////
export const selectCountry = (state: any) =>
  (state.entities[stateName] as ICountryState).publicCountries ?? [];
export const selectReceiverCountry = (state: any) =>
  (state.entities[stateName] as ICountryState).publicReceivingCountries ?? [];

///// Export actions ///////
export const { setPublicCountry, setPublicReceivingCountry } =
  CountrySlice.actions;

export { stateName, CountrySlice };
